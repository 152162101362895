@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply bg-background text-foreground;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@layer utilities {
  /* Typography */
  .boldheader1 {
    @apply text-7xl font-bold font-poppins;
  }
  .boldheader2 {
    @apply text-6xl font-bold font-poppins;
  }
  .boldheader3 {
    @apply text-5xl font-bold font-poppins;
  }
  .boldheader4 {
    @apply text-4xl font-bold font-poppins;
  }

  .semiboldheader1 {
    @apply text-5xl font-semibold font-inter;
  }
  .semiboldheader2 {
    @apply text-3xl font-semibold font-inter;
  }
  .semiboldheader3 {
    @apply text-2xl font-semibold font-inter;
  }
  .semiboldheader4 {
    @apply text-lg font-semibold font-inter;
  }

  .basetext {
    @apply text-base font-inter;
  }
  .boldbasetext {
    @apply text-base font-bold font-inter;
  }
  .navbarlinks {
    @apply text-2xl md:text-xl font-inter leading-none;
  }
  .footertext {
    @apply text-xs font-inter;
  }

  /* Decorators */
  .clickable {
    @apply boldbasetext;
    cursor: pointer;
    color: #0047a3;
  }
  .clickable:hover {
    color: #002366;
  }
  
  .inputField {
    padding: 990px;
    @apply bg-background border-2 border-gray-800 focus:border-purple-500 rounded-md p-1 text-foreground;
  }
  
  .selector {
    @apply w-max bg-purple-100 p-2 border-2 rounded-md border-purple-500;
  }
}

/* Page Transitions */
.fade-enter {
  opacity: 0;
  transform: scale(1);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  @apply hidden;
}

.fade-exit-active {
  opacity: 0;
  @apply hidden;
}

.backgroundImageFilter {
  background-color: rgba(0, 0, 0, 0.25);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.backgroundImageFilterLight {
  background-color: rgba(250, 244, 251, 0.25);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.backgroundImageFilterNews {
  background: linear-gradient(
    rgba(250, 244, 251, 100) 15%,
    rgba(250, 244, 251, 0) 35%,
    rgba(250, 244, 251, 0) 75%,
    rgba(250, 244, 251, 100) 95%
  );
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.backgroundImageFilterMobile {
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.backgroundImageFilter2 {
  background: linear-gradient(
    rgba(250, 244, 251, 0) 75%,
    rgba(250, 244, 251, 100) 90%
  );
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000099;
  z-index: 1000;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35rem;
  height: auto;
  transform: translate(-50%, -50%);
  background: rgb(250, 244, 251);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 3px #00000099;
  display: flex;
  flex-direction: column;
  align-content: center;
}

@layer components {
  .ql-editor a {
    @apply boldbasetext;
    cursor: pointer;
    color: #0047a3;
  }
  .ql-editor a:hover {
    color: #002366;
  }
}